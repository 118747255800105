import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../assets/css/post.css";
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import { BiDownvote, BiSolidDownvote } from "react-icons/bi";


const PostCard = ({ post }) => {
  console.log('post', post)
  const navigate = useNavigate();

  // upvote handling! 
  const [upvote,] = useState(post.upvote_count)
  const [downvote,] = useState(post.downvote_count)
  const [isUpvoted,] = useState(post.is_upvoted);
  const [isDownvoted,] = useState(post.is_downvoted);


  const handleClick = () => {
    navigate(`/detail/${post.slug}`);
  };


  return (
    <div onClick={handleClick} className='m-16 PostCard bg-white dark:bg-gray-800 rounded shadow-md'>
      <div className='border border-gray-400 rounded-lg p-4'>

        <div className='grid gap-4 text-dark font-bold dark:text-white justify-start'>
          <p>{post.description}</p>
        </div>

        <hr className='mt-3 p-2 border-t-2 border-slate-500' />

        <div className='flex justify-between'>
          <div className='flex'>
            <Link className='icon-container' rel="stylesheet">
              {isUpvoted ? <BiSolidUpvote className='text-xl text-green-500' /> : <BiUpvote className='text-xl' />}
              <p className='text-xs font-bold text-blue-500'>{upvote}</p>
            </Link>
            <Link className='icon-container' rel="stylesheet">
              {isDownvoted ? <BiSolidDownvote className='text-xl text-red-500' /> : <BiDownvote className='text-xl' />}
              <p className='text-xs font-bold text-blue-500'>{downvote}</p>
            </Link>
          </div>

          <div className='flex'>
            <button className='float-right m-2 p-1'>
              <p className='text-sm rounded-full text-dark font-bold dark:text-white'>Donors {post.donors_count}</p>
            </button>

            {/* <a className='float-right m-2  p-1'>
              <p className='text-sm rounded-full text-dark font-bold dark:text-white'>Donors {post.donors_count}</p>
            </a> */}

            <button className='float-right m-2  p-1'>
              <p className='text-sm rounded-full text-dark font-bold dark:text-white'>Reports {post.report_count}</p>
            </button>
          </div>

        </div>

      </div>
    </div>
  );
}

export default PostCard;
