// Sidebar.jsx
import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaUserCheck, FaSave } from "react-icons/fa";
import FilterPost from "./filterPost";

const Sidebar = () => {

  return (
    
    <div className="bg-white h-full max-h-full fixed dark:bg-gray-700 overflow-y-auto mt-12 sidebar">
      <ul className="list-none mt-15 p-2 ">

        <Link to='/'>
          <li className="flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white">
            <FaHome />
            <p className="pl-5">Home</p> 
          </li>
        </Link>

        <Link to='/saves'>
          <li className="flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white">
            <FaSave />
            <p className="pl-5">Saved Posts</p>
          </li>
        </Link>

        {/* <Link to='/satisfied'>
          <li className="flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white">
            <FaUserCheck />
            <p className="pl-5">Satisfied People</p>
          </li>
        </Link>
         */}

        <Link to='/history'>
          <li className="flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white">
            <FaUserCheck />
            <p className="pl-5">History</p>
          </li>
        </Link>

        <FilterPost className='w-full' />



        {/* <Link to='/reported'>
          <li className="flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white">
            <FaSave />
            <p className="pl-5">Reported Posts</p>
          </li>
        </Link> */}


      </ul>
    </div>
  );
};

export default Sidebar;