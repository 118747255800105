import React, { useState, useEffect } from 'react';
import { useGetPostDetailQuery, useGetPostDocFilesQuery, useDownvoteMutation, useUpvoteMutation, useSaveMutation, useCommentMutation, useGetCommentsQuery } from '../../services/postApis';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { multiFormatDateString } from '../../lib/utils/DateConvertor';
import "../assets/css/post.css";
import { Link } from 'react-router-dom';
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import { BiDownvote, BiSolidDownvote } from "react-icons/bi";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import CommentList from './comments';
import '../assets/css/comments.css';
import ReportModel from './report';
import DonateModel from './donate';




const PostDetailView = () => {

  const { slug } = useParams();
  const { data, isError, error, isLoading } = useGetPostDetailQuery(slug);
  console.log("data", data)
  const { data: docData } = useGetPostDocFilesQuery(slug);

  const [upvote, setUpvotes] = useState(0)
  const [downvote, setDownvote] = useState(0)
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const { data: commentData } = useGetCommentsQuery(slug);
  const [commentCount, setCommentCount] = useState(0);

  useEffect(() => {
    if (data) {
      setUpvotes(data.upvote_count);
      setDownvote(data.downvote_count);
      setIsUpvoted(data.is_upvoted);
      setIsDownvoted(data.is_downvoted);
      setIsSaved(data.is_saved);
      setCommentCount(data.comment_count);
    }
  }, [data]);

  useEffect(() => {
    if (commentData) {
      setCommentList(commentData);
    }
  }, [commentData]);

  const [upvoteMutation] = useUpvoteMutation()
  const [downvoteMutation] = useDownvoteMutation()
  const [saveMutation] = useSaveMutation()
  const [commentMutation] = useCommentMutation()

  const handleUpvote = (event) => {
    event.stopPropagation();
    if (!isUpvoted) {
      setUpvotes(upvote + 1)
      setIsUpvoted(true)
      if (isDownvoted) {
        setDownvote(downvote - 1)
        setIsDownvoted(false)
      };
      upvoteMutation(slug)
    } else {
      setUpvotes(upvote - 1)
      setIsUpvoted(false)
      upvoteMutation(slug)
    }
  }

  const handleDownvote = (event) => {
    event.stopPropagation();

    if (!isDownvoted) {
      setDownvote(downvote + 1)
      setIsDownvoted(true)
      if (isUpvoted) {
        setUpvotes(upvote - 1)
        setIsUpvoted(false)
      };
      downvoteMutation(slug)
    } else {
      setDownvote(downvote - 1)
      setIsDownvoted(false)
      downvoteMutation(slug)
    }
  }

  const handleSave = (event) => {
    if (!isSaved) {
      setIsSaved(true)
      saveMutation(slug)
    } else {
      setIsSaved(false)
      saveMutation(slug)
    }
  }

  const handleSubmitComment = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("body", event.target.body.value);
    formData.append("slug", event.target.slug.value);

    try {
      const response = await commentMutation(formData);
      setCommentList([...commentList, response.data]);
      setCommentCount(commentCount + 1);
      event.target.reset();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isLoading) {
    return <div><CircularProgress />Loading...</div>;
  }
  if (isError) return <div>Error: {error.message}</div>;
  
  return (
    <div className='bg-white PostDetailView dark:bg-gray-700 text-dark-1 dark:text-gray-100 mt-10 max-w-88 p-5 mb-3 '>
      <div className='grid grid-cols-2 gap-4'>
        <div>
          <video className='detailVideo' controls controlsList="nodownload">
            <source src={data.post_videos.videos.seeker_video} type="video/mp4" />
          </video>
        </div>
        <div className=''>
          <video className='detailVideo' controls controlsList="nodownload">
            <source src={data.post_videos.videos.place_video} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className='flex justify-between py-4'>

        <div className='flex'>
          <Link className='custom-link'
            to={`/profiles/${data.creator}`}>
            <p className='text-xs font-bold text-blue-500'>{data.creator}</p>
          </Link>

          <Link className='custom-link' onClick={handleUpvote}>
            {isUpvoted ? <BiSolidUpvote className='text-xl text-green-500' /> : <BiUpvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500'>{upvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleDownvote}>
            {isDownvoted ? <BiSolidDownvote className='text-xl' /> : <BiDownvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500'>{downvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleSave}>
            {isSaved ? <FaBookmark className='text-xl' /> : <FaRegBookmark className='text-xl' />}
          </Link>
        </div>

        <div className="flex justify-center space-x-4">
          <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded">
            <DonateModel slug={data.slug} count={data.donors_count} />
          </button>
          <button className="bg-teal-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded">
            <ReportModel slug={data.slug} count={data.report_count} />
          </button>
        </div>

      </div>

      <div className='justify-between py-4 grid grid-cols-2 gap-4'>
        <div className='flex-col seeker-info'>
          <p className='text-xl font-bold'>Seeker Details</p>
          <p className=''>Seeker: {data.seeker}</p>
          <p className=''>Need: {data.need}</p>
          <p className=''>From: {data.address}</p>
          <p className=''>verified: {data.verified}%</p>
          <p className=''>Phone # {data.phone_number}</p>
          <p className='text-dark-1 dark:text-white'>Created at: {multiFormatDateString(data.created)}</p>
        </div>

        <div className='flex-col seeker-info'>
          <p className='text-xl font-bold'>Seeker Bank Details</p>
          <p className=''>Account Holder: {data.bank_detail.account_holder}</p>
          <p className=''>Bank Name: {data.bank_detail.bank_name}</p>
          <p className=''>Account Number: {data.bank_detail.account_number}</p>
        </div>
      </div>

      <div className="flex py-4 post-documents">
        <p className='text-xl font-bold'>Documents</p>
        {docData && docData.documents &&
          Object.keys(docData.documents).map((docKey) => (
            <Link
              key={docKey}
              to={docData.documents[docKey]}
              target="_blank"
              rel="noopener noreferrer"
              className='flex items-center ml-2 text-bold text-blue-800'
            >
              <p className='p-2 text-dark-1 dark:text-gray-100'>
                {docKey.replace(/_/g, ' ')}  {/* Convert key names like 'electric_bill' to 'Electric Bill' */}
              </p>
            </Link>
          ))
        }
      </div>

      <div className='description'>
        <div className='text-xl font-bold'>Discription</div>
        <p className='text-dark-1 dark:text-white'>{data.description}</p>
      </div>

      <div className='py-4 comments bg-white dark:bg-gray-700 '>
        <p className='text-xl font-bold text-dark-1 dark:text-white'>{commentCount} Comments</p>

        <form onSubmit={handleSubmitComment} className=' bg-white dark:bg-gray-700 comment-form  '>
          <input type='text' placeholder='Add a comment...' name='body' className=' text-dark-1 dark:text-white bg-white dark:bg-gray-700' />
          <input type='hidden' value={data.slug} name='slug' />
          <button type='submit'>Comment</button>
        </form>

        {commentList && commentList.length > 0 ? (
            [...commentList].reverse().map(comment => (
                <CommentList key={comment.id} comment={comment} />
            ))
        ) : (
            <p>No comments yet.</p>
        )}


      </div>

    </div>
  );
}

export default PostDetailView
